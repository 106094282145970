import { Link, NavLink, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DrawerComp from './Drawer';
import InfoIcon from '@mui/icons-material/Info';
import ExploreIcon from '@mui/icons-material/Explore';
import LoginIcon from '@mui/icons-material/Login';
import { fetchUserDetailsAction } from 'src/redux/slices/users/usersSlices';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';

const PublicNavbar = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.users);
  const { userAuth } = store;

  useEffect(() => {
    if (userAuth?.token) {
      dispatch(fetchUserDetailsAction(userAuth?._id));
    }
  }, [dispatch, userAuth?._id, userAuth?.token]);
  const [showMenu] = useState(null);
  const location = useLocation();

  return (
    <div className="w-full">
      <nav className="bg-transparent xl:block hidden z-50 ">
        <div className="mx-auto container px-6  xl:py-0">
          <div className="flex items-center justify-between">
            <div className="flex w-full sm:w-auto items-center sm:items-stretch justify-end sm:justify-start">
              <Link to="/">
                <div className="flex items-center">
                  <img
                    className=" w-40 h-20 object-contain"
                    src={process.env.REACT_APP_IMAGE_URL}
                    alt={process.env.REACT_APP_NAME}
                  />
                </div>
              </Link>
            </div>
            <div className="flex">
              <div className="hidden xl:flex md:mr-6 xl:mr-16">
                <NavLink
                  to="/marketplace"
                  className={
                    location.pathname === '/marketplace' ? 'active' : 'nav-link'
                  }
                >
                  <div className="flex px-5 items-center py-6 text-base leading-5   focus:outline-none transition font-sarabun font-Light duration-150 ease-in-out">
                    <span className="mr-2">
                      <ExploreIcon />
                    </span>
                    Explore
                  </div>
                </NavLink>
                <NavLink
                  to="/about"
                  className={
                    location.pathname === '/about' ? 'active' : 'nav-link'
                  }
                >
                  <div className="flex px-5 items-center py-6 text-base leading-5   focus:outline-none transition font-sarabun font-Light duration-150 ease-in-out">
                    <span className="mr-2">
                      <InfoIcon />
                    </span>
                    About
                  </div>
                </NavLink>
              </div>
              {userAuth ? (
                <button>
                  <Link to={`/dashboards/dashboard/${userAuth?._id}`}>
                    <div className="cursor-pointer flex items-center text-sm  border-transparent rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out">
                      <span className="text-base  font font-Regular pr-2 pl-20 capitalize">
                        Welcome {userAuth?.firstName}
                      </span>
                      <img
                        className="rounded-full h-10 w-10 object-cover"
                        src={userAuth?.profilePhoto}
                        alt={userAuth?.firstName}
                      />
                    </div>
                  </Link>
                </button>
              ) : (
                <div className="hidden xl:flex items-center">
                  <div className="relative px-4 md:mr-6 my-2">
                    <Link to="/login">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="font-sarabun font-Light"
                      >
                        Login
                      </Button>
                    </Link>
                  </div>
                  <Link to="/register">
                    <Button
                      variant="contained"
                      color="primary"
                      className="font-sarabun font-Light"
                    >
                      Register
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <nav>
        <div className=" px-6 w-full  flex items-center justify-between xl:hidden  bg-transparent  top-0 z-40">
          {/* //LOGO ICON */}
          <div className="w-24">
            <Link to="/">
              <img
                className=" w-30 h-16 object-contain"
                src={process.env.REACT_APP_IMAGE_URL}
                alt={process.env.REACT_APP_NAME}
              />
            </Link>
          </div>
          {!userAuth ? (
            <div className="relative mt-2 ">
              <Link to="/register">
                <span className="relative inline-flex items-center justify-center px-6 py-2 overflow-hidden font-bold  rounded-md shadow-2xl group">
                  <span className="absolute inset-0 w-full h-full transition duration-300 ease-out opacity-0 bg-gradient-to-br from-[#582D98] via-[#582D98] to-white group-hover:opacity-100"></span>
                  <span className="absolute top-0 left-0 w-full bg-gradient-to-b from-white to-transparent opacity-5 h-1/3"></span>
                  <span className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-white to-transparent opacity-5"></span>
                  <span className="absolute bottom-0 left-0 w-4 h-full bg-gradient-to-r from-white to-transparent opacity-5"></span>
                  <span className="absolute bottom-0 right-0 w-4 h-full bg-gradient-to-l from-white to-transparent opacity-5"></span>
                  <span className="absolute inset-0 w-full h-full border border-white rounded-md opacity-10"></span>
                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-56 group-hover:h-56 opacity-5"></span>
                  <span className="relative">Sign Up</span>
                </span>
              </Link>
            </div>
          ) : null}
          {showMenu ? (
            ''
          ) : (
            //MENU ICON
            <>
              <DrawerComp>
                <List className="py-5">
                  <NavLink to="/marketplace">
                    <ListItem className="py-3">
                      <ListItemButton className="flex items-center justify-between ">
                        <ListItemIcon>
                          <ExploreIcon />
                        </ListItemIcon>
                        <ListItemText primary="Explore" />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                  <NavLink to="/about">
                    <ListItem className="py-3">
                      <ListItemButton className="flex items-center">
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="About" />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                  <Divider
                    style={{
                      margin: '5px',
                      backgroundColor: '#fff',
                    }}
                  />
                  {userAuth ? (
                    <Link to="/login">
                      <ListItem>
                        <ListItemButton className="flex items-center font-sarabun font-Light transition duration-150 ease-in-out text-sm">
                          <ListItemText
                            primary={`Welcome ${userAuth?.firstName}`}
                          />
                          <ListItemIcon>
                            <img
                              className="rounded-full h-10 w-10 object-cover"
                              src={userAuth?.profilePhoto}
                              alt={userAuth?.firstName}
                            />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  ) : (
                    <Link to="/login">
                      <ListItem>
                        <ListItemButton className="flex items-center font-sarabun font-Light transition duration-150 ease-in-out text-sm">
                          <ListItemIcon>
                            <LoginIcon />
                          </ListItemIcon>
                          <ListItemText primary="Login" />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  )}
                </List>
              </DrawerComp>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default PublicNavbar;
