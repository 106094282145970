import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from 'src/utils/baseURL';

const resetContactMsgAction = createAction('contact/reset');
//action
export const sendContactMsgAction = createAsyncThunk(
  'contact/sent',
  async (contact, { rejectWithValue, dispatch }) => {
    //http call
    try {
      const { data } = await axios.post(`${baseUrl}/api/contact`, {
        senderName: contact?.senderName,
        senderEmail: contact?.senderEmail,
        message: contact?.message,
      });
      dispatch(resetContactMsgAction());
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchContactMsgAction = createAsyncThunk(
  'contact/get',
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      const { data } = await axios.get(`${baseUrl}/api/contact`, config);
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteContactMsgAction = createAsyncThunk(
  'contact/delete',
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(
        `${baseUrl}/api/contact/${id}`,
        config
      );
      dispatch(fetchContactMsgAction());
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

// delete all
export const deleteAllContactMsgAction = createAsyncThunk(
  'contact/deleteAll',
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      const { data } = await axios.delete(`${baseUrl}/api/contact`, config);
      dispatch(fetchContactMsgAction());
      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//initial state

const sendContactMsgSlice = createSlice({
  name: 'contact',
  initialState: {},

  extraReducers: (builder) => {
    builder.addCase(sendContactMsgAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(resetContactMsgAction, (state) => {
      state.isMessageSent = true;
    });
    builder.addCase(sendContactMsgAction.fulfilled, (state, action) => {
      state.loading = false;
      state.contactMsg = action?.payload;
      state.isMessageSent = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(sendContactMsgAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.payload?.error;
    });

    //get all contact messages
    builder.addCase(fetchContactMsgAction.pending, (state) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });

    builder.addCase(fetchContactMsgAction.fulfilled, (state, action) => {
      state.loading = false;
      state.sentContactMsgs = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });

    builder.addCase(fetchContactMsgAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.payload?.error;
    });

    //delete contact message
    builder.addCase(deleteContactMsgAction.pending, (state) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });

    builder.addCase(deleteContactMsgAction.fulfilled, (state, action) => {
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });

    builder.addCase(deleteContactMsgAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.payload?.error;
    });

    //delete all contact messages

    builder.addCase(deleteAllContactMsgAction.pending, (state) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });

    builder.addCase(deleteAllContactMsgAction.fulfilled, (state, action) => {
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });

    builder.addCase(deleteAllContactMsgAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.payload?.error;
    });
  },
});

export default sendContactMsgSlice.reducer;
