import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminRoute = () => {
  const user = useSelector((state) => state?.users);
  const { userAuth } = user;
  return <>{userAuth?.isAdmin ? <Outlet /> : <Navigate to="/login" />}</>;
};

export default AdminRoute;
