import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Tooltip,
  ListItem,
  List,
  ListItemText,
  Dialog,
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar({
  userAuth,
  userDetails,
  isUpdated,
  loading,
  appErr,
  serverErr,
  updateUserAction,
  logoutAction,
  dispatch,
}) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountType: userDetails?.accountType,
      pincode: userDetails?.pincode,
    },
    onSubmit: () => {
      const data = {
        accountType: 'provider',
        pincode: formik.values.pincode,
      };
      dispatch(updateUserAction(data));
    },
  });

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (isUpdated) {
    toast('Please login again to see changes');
    setTimeout(() => {
      dispatch(logoutAction());
    }, 3000);
  }

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block',
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none',
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                width: 52,
              }}
            >
              <Logo />
            </Box>
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <SidebarMenu userAuth={userAuth} />
        </Scrollbar>

        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10],
          }}
        />
        {userAuth?.accountType === 'rider' ? (
          <Tooltip
            title=" Full access to all features, Create as many projects, Sell
                    your projects easily, Find buyers easily, Connect with other
                    creators."
          >
            <Box p={1}>
              <Button
                onClick={handleClickOpen}
                variant="contained"
                size="large"
                fullWidth
                style={{
                  color: theme.colors.alpha.trueWhite[100],
                }}
              >
                Become a provider
              </Button>
            </Box>
          </Tooltip>
        ) : null}
      </SidebarWrapper>

      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5),
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52,
                }}
              >
                <Logo />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu userAuth={userAuth} />
          </Scrollbar>

          <Divider
            sx={{
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          {userAuth?.accountType === 'rider' ? (
            <Tooltip
              title=" Full access to all features, Create as many projects, Sell
                    your projects easily, Find buyers easily, Connect with other
                    creators."
            >
              <Box p={1}>
                <Button
                  onClick={handleClickOpen}
                  variant="contained"
                  size="large"
                  fullWidth
                  style={{
                    color: theme.colors.alpha.trueWhite[100],
                  }}
                >
                  Become a provider
                </Button>
              </Box>
            </Tooltip>
          ) : null}
        </SidebarWrapper>
      </Drawer>
      <Dialog onClose={handleClose} open={open}>
        <h3 className="text-center pt-2 text-red-400">
          {serverErr || appErr ? (
            <div className="rounded-lg text-center py-4 lg:px-4">
              <div
                className="p-2 bg-purple-800 rounded-lg  items-center text-indigo-100 leading-none  flex lg:inline-flex"
                role="alert"
              >
                <span className="font-semibold mr-2 text-left flex-auto">
                  {serverErr} - {appErr}
                </span>
              </div>
            </div>
          ) : null}
        </h3>
        {serverErr || appErr ? (
          <div className="rounded-lg text-center py-4 lg:px-4">
            <div
              className="p-2 bg-purple-800  border-2 border-red-700 rounded-lg  items-center text-indigo-100 leading-none  flex lg:inline-flex"
              role="alert"
            >
              <span className="font-semibold mr-2 text-left flex-auto">
                {serverErr} - {appErr}
              </span>
            </div>
          </div>
        ) : null}
        <List sx={{ pt: 0 }}>
          <ListItem>
            <div className="flex">
              <span className="bg-gradient-to-r  from-green-300 via-purple-500 to-purple-800 bg-clip-text text-4xl font-extrabold text-transparent font-sarabun md:text-5xl">
                Welcome to the community of providers!
              </span>
              <span className="text-3xl">🎉</span>
            </div>
          </ListItem>
          <ListItem>
            <ListItemText
              sx={{ m: 1, width: '35ch' }}
              primaryTypographyProps={{ variant: 'h5' }}
            >
              <p className="mx-auto mt-4 max-w-xl text-base md:text-lg  font-sarabun  font-Regular pb-10">
                We are happy to have you here. We are a community of providers
                who are passionate about what we do.
              </p>
              <span className="bg-gradient-to-r from-green-300 via-purple-500 to-purple-800 bg-clip-text text-xl font-extrabold text-transparent font-sarabun">
                Please confirm your choice
              </span>
            </ListItemText>
          </ListItem>
          <ListItem>
            <div className="mt-8 md:flex-row md:flex  justify-center flex-wrap-reverse md:gap-5">
              <button
                onClick={handleClose}
                className="block w-full rounded border mb-5 font-sarabun border-purple-600 px-8 md:px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
              >
                I'm not sure
              </button>
              <form onSubmit={formik.handleSubmit}>
                <LoadingButton
                  loading={loading}
                  variant="outlined"
                  color="primary"
                  type="submit"
                  sx={{ mb: 2, width: '30ch' }}
                >
                  Let's go
                </LoadingButton>
              </form>
            </div>
          </ListItem>
        </List>
      </Dialog>
    </>
  );
}

export default Sidebar;
