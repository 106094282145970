import { useRoutes } from 'react-router-dom';
import { useState, createContext, useMemo, useContext, useEffect } from 'react';
import router from 'src/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import IconButton from '@mui/material/IconButton';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from 'src/theme/base';

const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  const content = useRoutes(router);
  const colorMode = useContext(ColorModeContext);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CssBaseline />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <IconButton
        onClick={colorMode.toggleColorMode}
        style={{
          position: 'absolute',
          // top: 45,
          // right: 80,
        }}
        className="top-10 right-20 md:right-[450px] md:top-[48px]"
      >
        {colorMode.darkMode ? <></> : <></>}
      </IconButton>
      {content}
    </LocalizationProvider>
  );
}
export default function ToggleColorMode() {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem('theme') || 'LightTheme'
  );

  useEffect(() => {
    const theme = localStorage.setItem('theme', darkMode);
    return theme;
  }, [darkMode]);

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme) {
      setDarkMode(theme);
    }
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setDarkMode((prev) =>
          prev === 'DarkTheme' ? 'LightTheme' : 'DarkTheme'
        );
      },
    }),
    []
  );

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme') || darkMode;
    setDarkMode(currentTheme);
  }, [darkMode]);

  const theme = useMemo(() => {
    return themeCreator(darkMode);
  }, [darkMode]);

  return (
    <StylesProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StylesProvider>
  );
}
