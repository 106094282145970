import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProviderRoute = () => {
  const user = useSelector((state) => state?.users);
  const { userAuth } = user;
  return (
    <>
      {userAuth?.accountType === 'provider' ? (
        <Outlet />
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default ProviderRoute;
