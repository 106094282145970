import { DarkTheme } from './schemes/DarkTheme';
import { LightTheme } from './schemes/LightTheme';

export function themeCreator(theme) {
  return themeMap[theme];
}

const themeMap = {
  DarkTheme,
  LightTheme,
};
