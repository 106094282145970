import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AdminRoute from './utils/AdminRoute';
import ProviderRoute from './utils/ProviderRoute';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import RequireAuth from 'src/utils/RequireAuth';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Homepage = Loader(lazy(() => import('src/content/homepage')));
const Login = Loader(lazy(() => import('src/content/login')));
const Register = Loader(lazy(() => import('src/content/register')));
const Marketplace = Loader(lazy(() => import('src/content/marketplace')));
const ProjectPage = Loader(
  lazy(() => import('src/content/marketplace/ProjectPage'))
);
const Contact = Loader(lazy(() => import('src/content/contact')));
const About = Loader(lazy(() => import('src/content/About')));
// Dashboards

const Dashboard = Loader(
  lazy(() => import('src/content/dashboards/Dashboard'))
);

const ForgotPassword = Loader(lazy(() => import('src/content/ForgotPassword')));
const ResetPassword = Loader(lazy(() => import('src/content/ResetPassword')));
// Applications

//Comments
const UpdateComment = Loader(
  lazy(() => import('src/content/Comments/UpdateComment'))
);
const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const CategoryList = Loader(
  lazy(() => import('src/content/applications/Categories/CategoryList'))
);
const UpdateCategory = Loader(
  lazy(() => import('src/content/applications/Categories/UpdateCategory'))
);
const AddNewCategory = Loader(
  lazy(() => import('src/content/applications/Categories/AddNewCategory'))
);
const ContactMsgs = Loader(
  lazy(() => import('src/content/applications/ContactMsgs'))
);

const UsersList = Loader(
  lazy(() => import('src/content/applications/UsersList'))
);
const SidebarContent = Loader(
  lazy(() => import('src/content/applications/Messenger/SidebarContent'))
);
const Projects = Loader(
  lazy(() => import('src/content/applications/Projects'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components
const JobDetails = Loader(
  lazy(() => import('src/content/marketplace/ProjectPage/JobDetails'))
);
const CreateProject = Loader(
  lazy(() => import('src/content/marketplace/CreateProject'))
);

const UpdateProject = Loader(
  lazy(() => import('src/content/marketplace/UpdateProject'))
);
// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);

const EmailVerification = Loader(
  lazy(() => import('src/content/pages/Status/EmailVerification'))
);

const routes = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Homepage />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },

      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'marketplace',
        element: <Marketplace />,
      },
      {
        path: 'marketplace/search',
        element: <Marketplace />,
      },
      {
        path: 'marketplace/:slug/:name',
        element: <ProjectPage />,
      },
      {
        path: 'marketplace/:id',
        element: <JobDetails />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'homepage',
        element: <Navigate to="/" replace />,
      },
      {
        path: '/:id/verify-account/:token',
        element: <EmailVerification />,
      },
      {
        path: '/:id/reset-password/:token',
        element: <ResetPassword />,
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: '500',
            element: <Status500 />,
          },
        ],
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },

  {
    path: '/dashboards',
    element: <RequireAuth />,
    children: [
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="dashboard" replace />,
          },
          {
            path: 'dashboard/:id',
            element: <Dashboard />,
          },
          {
            path: 'messenger/:id',
            element: <Messenger />,
            children: [
              {
                path: ':id',
                element: <SidebarContent />,
              },
            ],
          },

          {
            path: '',
            element: <ProviderRoute />,
            children: [
              {
                path: 'projects/:id',
                element: <Projects />,
              },
              {
                path: 'create-project/:id',
                element: <CreateProject />,
              },
              {
                path: 'update-project/:id',
                element: <UpdateProject />,
              },
            ],
          },

          {
            path: 'update-comment/:id',
            element: <UpdateComment />,
          },
          {
            path: '',
            element: <AdminRoute />,
            children: [
              {
                path: 'users-list/:id',
                element: <UsersList />,
              },

              {
                path: 'add-category/:id',
                element: <AddNewCategory />,
              },
              {
                path: 'category-list/:id',
                element: <CategoryList />,
              },
              {
                path: 'update-category/:id',
                element: <UpdateCategory />,
              },
              {
                path: 'contact-msg/:id',
                element: <ContactMsgs />,
              },
            ],
          },
          {
            path: 'profile',
            children: [
              {
                path: '',
                element: <Navigate to="details/:id" replace />,
              },
              {
                path: 'details/:id',
                element: <UserProfile />,
              },
              {
                path: 'settings/:id',
                element: <UserSettings />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
