import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import PublicNavbar from 'src/layouts/SidebarLayout/Header/PublicNavbar';
import Footer from 'src/components/Footer';
import { Box } from '@mui/material';

const BaseLayout = ({ children }) => {
  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',
      }}
    >
      <Box display="flex" justifyContent="center" py={3} alignItems="center">
        <PublicNavbar />
      </Box>
      {children || <Outlet />}
      <Footer />
    </Box>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
};

export default BaseLayout;
