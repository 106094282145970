import { Link } from 'react-router-dom';
import { Box, Container, Typography, styled } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
      >
        <Box>
          <div className="text-purple-100">
            &copy; {new Date().getFullYear()} |{' '}
            <Link to="/" className="text-purple-300">
              {process.env.REACT_APP_NAME}
            </Link>
          </div>
        </Box>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 },
          }}
          variant="subtitle1"
        >
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center text-gray-200 sm:justify-start">
            <span>
              <FacebookIcon />
            </span>
            <span className="ml-3 ">
              <TwitterIcon />
            </span>
            <span className="ml-3 ">
              <LinkedInIcon />
            </span>
            <span className="ml-3 ">
              <InstagramIcon />
            </span>
          </span>
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
